.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap");

:root {
	--ui-kit-text-font-family: "Nunito Sans";
	--ui-kit-body-font-size: 1rem;
	--ui-kit-body-font-weight: 400;
	--ui-kit-body-line-height: 1.5;
	--ui-kit-body-bg: #fff;
	--ui-kit-body-color: #212529;

	--ui-kit-viewer-bg: #ffffff;

	--ui-kit-heading-color: inherit;
	--ui-kit-primary-color: #0d6efd;

	--ui-kit-secondary-color: #rgba(33, 37, 41, 0.75);
	--ui-kit-secondary-bg: #e9ecef;

	--ui-kit-border-width: 1px;
	--ui-kit-border-style: solid;
	--ui-kit-border-color: #dee2e6;
	--ui-kit-border-color-translucent: rgba(0, 0, 0, 0.175);
	--ui-kit-border-radius: 0.375rem;

	--ui-kit-list-hover-color: #000;
	--ui-kit-list-hover-bg: #f8f9fa;

	--ui-kit-list-selected-color: var(--ui-kit-body-color);
	--ui-kit-list-selected-bg: #0d6efd4c;

	--ui-kit-spinner-size: 2rem;
	--ui-kit-spinner-thickness: 0.25em;
	--ui-kit-spinner-color: var(--ui-kit-primary-color);
	--ui-kit-spinner-animation-speed: 0.75s;
	--ui-kit-spinner-animation-name: spinner;

	--ui-kit-select-bg-img: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 4L5.92929 7.92929C5.96834 7.96834 6.03166 7.96834 6.07071 7.92929L10 4' stroke='%23343a40' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

:root[data-theme="dark"] {
	--ui-kit-body-color: #e4e4e4;
	--ui-kit-body-bg: #212529;

	--ui-kit-viewer-bg: #212529;

	--ui-kit-secondary-color: rgba(173, 181, 189, 0.75);
	--ui-kit-secondary-bg: #343a40;

	--ui-kit-border-color: #495057;

	--ui-kit-list-hover-color: #fff;
	--ui-kit-list-hover-bg: #2b3035;

	--ui-kit-select-bg-img: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 4L5.92929 7.92929C5.96834 7.96834 6.03166 7.96834 6.07071 7.92929L10 4' stroke='%23adb5bd' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	padding: 0.375rem 0.75rem;
	overflow: hidden;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: calc(1.5em + 0.75rem);
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	color: #495057;
	content: "Browse";
	background-color: #e9ecef;
	border-left: inherit;
	border-radius: 0 0.25rem 0.25rem 0;
}

.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}

.progress-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	text-align: center;
	background-color: #007bff;
	/* transition: width .6s ease; */
}

.spinner-wrapper {
	width: 100%;
	height: 100%;
}

.spinner {
	display: inline-block;
	width: var(--ui-kit-spinner-size);
	height: var(--ui-kit-spinner-size);
	vertical-align: -0.125em;
	border-radius: 50%;
	color: var(--ui-kit-spinner-color);
	animation: var(--ui-kit-spinner-animation-speed) linear infinite
		var(--ui-kit-spinner-animation-name);
	border: var(--ui-kit-spinner-thickness) solid currentcolor;
	border-right-color: transparent;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

#example-container {
	display: flex;
	justify-content: space-between;
	height: 100%;
}

#file-viewer {
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #0087ee20 !important;
	border-radius: 1rem;
}

#file-viewer > canvas {
	height: 100% !important;
	width: 100% !important;
	background-color: #0087ee20 !important;
	border-radius: 1rem;
}

#file-viewer {
	background-color: var(--ui-kit-viewer-bg) !important;
	color: var(--ui-kit-body-color) !important;
}

.cadex-viewcube > canvas {
	height: 100% !important;
	width: 100% !important;
}

/* .cadex-logo{
  visibility: hidden !important;
 } */

.jstree-container-ul {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.jstree-anchor.jstree-hovered,
.jstree-wholerow.jstree-wholerow-hovered {
	background: var(--ui-kit-list-hover-bg) !important;
	color: var(--ui-kit-list-hover-color) !important;
}

.jstree-default:not(.jstree-checkbox-no-clicked)
	.jstree-wholerow.jstree-wholerow-clicked {
	background: var(--ui-kit-list-selected-bg) !important;
	color: var(--ui-kit-list-selected-color) !important;
}

.jstree-default:not(.jstree-checkbox-no-clicked) .jstree-anchor.jstree-clicked {
	background: transparent !important;
	color: var(--ui-kit-list-selected-color) !important;
}

:root[data-theme="dark"] .jstree-themeicon-custom,
:root[data-theme="dark"] .jstree-icon.jstree-ocl {
	filter: invert(1);
}

::-webkit-scrollbar {
	width: 4px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
	background-color: rgba(0, 0, 0, 0.4);
}

#viewer-panel {
	width: 20rem;
	max-width: calc(100% - 2rem);
	position: absolute;
	left: 1rem;
	top: 1rem;
}

#viewer-panel > div:not(:first-child) {
	margin-top: 1rem;
}

#progress {
	width: 20rem;
	max-width: 80%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 1rem;
	visibility: hidden;
}
